import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Modal } from 'semantic-ui-react';
import { createLead, resetNewLead, fetchCityStateFromZip } from '../../actions/lead';
import { Input,Select, Form } from 'formsy-semantic-ui-react';
import { addValidationRule } from 'formsy-react';
import './CreateLead.css';

const moveSizes = [
  {
    val_to_submit: 'studio',
    text: 'studio',
    value: 'studio',
  },
  {
    val_to_submit: 'one bed apartment',
    text: 'one bed apartment',
    value: 'one bed apartment',
  },
  {
    val_to_submit: 'one bed house',
    text: 'one bed house',
    value: 'one bed house',
  },
  {
    val_to_submit: 'two bed apartment',
    text: 'two bed apartment',
    value: 'two bed apartment',
  },
  {
    val_to_submit: 'two bed house',
    text: 'two bed house',
    value: 'two bed house',
  },
  {
    val_to_submit: 'three bed apartment',
    text: 'three bed apartment',
    value: 'three bed apartment',
  },
  {
    val_to_submit: 'three bed house',
    text: 'three bed house',
    value: 'three bed house',
  },
  {
    val_to_submit: 'four bed apartment',
    text: 'four bed apartment',
    value: 'four bed apartment',
  },
  {
    val_to_submit: 'four bed house',
    text: 'four bed house',
    value: 'four bed house',
  },
  {
    val_to_submit: 'five bed apartment and more',
    text: 'five bed apartment and more',
    value: 'five bed apartment and more',
  },
  {
    val_to_submit: 'five bed house and more',
    text: 'five bed house and more',
    value: 'five bed house and more',
  },
  {
    val_to_submit: 'two bed house',
    text: 'commercial',
    value: 'commercial',
  },
];

const validStates = {
  AL: true,
  AK: true,
  AZ: true,
  AR: true,
  CA: true,
  CO: true,
  CT: true,
  DC: true,
  DE: true,
  FL: true,
  GA: true,
  HI: true,
  ID: true,
  IL: true,
  IN: true,
  IA: true,
  KS: true,
  KY: true,
  LA: true,
  ME: true,
  MD: true,
  MA: true,
  MI: true,
  MN: true,
  MS: true,
  MO: true,
  MT: true,
  NE: true,
  NV: true,
  NH: true,
  NJ: true,
  NM: true,
  NY: true,
  NC: true,
  ND: true,
  OH: true,
  OK: true,
  OR: true,
  PA: true,
  PR: true,
  RI: true,
  SC: true,
  SD: true,
  TN: true,
  TX: true,
  UT: true,
  VT: true,
  VA: true,
  WA: true,
  WV: true,
  WI: true,
  WY: true,
};

const CreateLead = ({ dispatch, newLead }) => {
  const [from_state, setFromState] = useState(newLead.from_state || ' ');
  const [from_city, setFromCity] = useState(newLead.from_city || ' ');
  const [to_state, setToState] = useState(newLead.to_state || ' ');
  const [to_city, setToCity] = useState(newLead.to_city || ' ');
  const [isCreating, setIsCreating] = useState(false);

  const handleChangeFromZip = e => {
    const newFromZip = e.target.value.trim();
    if(newFromZip.length === 5) 
      dispatch(fetchCityStateFromZip(newFromZip,'from','create'));  
  };
 
  const handleChangeToZip = e => {
    const newToZip = e.target.value.trim();
    if(newToZip.length === 5)
      dispatch(fetchCityStateFromZip(newToZip,'to','create'));
  };
  
  const addLead = (newLead) => {
    const moveSizeToSubmit = moveSizes.find(size => (size.value === newLead.movesize)).val_to_submit;
    setIsCreating(true)
    dispatch(
      createLead({
        ...newLead,
        movesize: moveSizeToSubmit,
      }),
    );
  };

  useEffect(() => {
    if(newLead.created){
      try{
        document.getElementsByClassName('close icon')[0].click();
        dispatch(resetNewLead());
      }catch(e){
        console.log("Failed to close dimmer.")
      }
    }else if(newLead.error){
      setIsCreating(false)
    }
  },[dispatch, newLead.created, newLead.error]);

  useEffect(() => {
    setFromState(newLead.from_state);
    setFromCity(newLead.from_city);
    setToState(newLead.to_state);
    setToCity(newLead.to_city);
  },[newLead.from_state, newLead.from_city, newLead.to_state, newLead.to_city]);
  
  const handleModalOpen = () => {
    // reset state
    setFromState('');
    setFromCity('');
    setToState('');
    setToCity('');
    setIsCreating(false);
  };

  addValidationRule('isFuture', function (values, value, array) {
    return moment(value).isAfter(moment())
  });

  addValidationRule('isState', function (values, value, array) {
    return value && !!validStates[value.toUpperCase()];
  });

  return (
    <Modal
      trigger={
        <Button id="create_lead"  onClick={handleModalOpen}>
          Create New Lead
        </Button>
      } 
      closeOnDimmerClick={false}
      closeIcon={true}
    >
      <Modal.Header>Create New Lead</Modal.Header>
      <Modal.Content>
        <Form noValidate  onValidSubmit={addLead}>
          {newLead.source ? <h1>SOURCE: {newLead.source}</h1> : null}
          <Form.Group widths={2}>
            <Input
              disabled={newLead.created}
              required
              type="date"
              name="movedate"
              validations="isFuture"
              label="Move Date"
            />
            <Input
              disabled={newLead.created}
              required
              type="text"
              name="name"
              validations="minLength:1"
              label="Name"
              defaultValue={newLead.name}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Input
              disabled={newLead.created}
              required
              type="text"
              name="email"
              label="Email Address"
              validations="isEmail"
            />
            <Input
              disabled={newLead.created}
              required
              type="text"
              name="phone"
              validations="isNumeric,maxLength:11,minLength:10"
              label="Phone"
              defaultValue={newLead.phone}
            />
          </Form.Group>
          <h5 className="heading">Move Size</h5>
            <Select
              disabled={newLead.created}
              required
              name="movesize"
              options={moveSizes}
              label="Move Size"
              width={6}
            />
          <h5 className="heading">Moving From</h5>
            <Form.Group widths={3}>
              <Input
                disabled={newLead.created}
                required
                name="from_zip"
                type="text"
                onChange={handleChangeFromZip}
                validations="isNumeric,isLength:5"
                label="Zipcode"
              />
              
              <Input
                disabled={newLead.created}
                required
                name="from_state"
                type="text"
                validations="isState"
                label="State"
                value={from_state}
              />
              <Input
                disabled={newLead.created}
                required
                type="text"
                name="from_city"
                label="City"
                value={from_city}
              />
            </Form.Group>
          <h5 className="heading">Moving To</h5>
            <Form.Group widths={3}>
              <Input
                disabled={newLead.created}
                required
                type="text"
                name="to_zip"
                onChange={handleChangeToZip}
                validations="isNumeric,isLength:5"
                label="Zipcode"
              />
              <Input
                disabled={newLead.created}
                required
                type="text"
                name="to_state"
                validations="isState"
                value={to_state}
                label="State"
              />
              <Input
                disabled={newLead.created}
                required
                type="text"
                name="to_city"
                value={to_city}
                label="City"
              />
            </Form.Group>
          {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
          <h5 className="heading"/>
          <Form.Group widths={4}>
            <Button
              floated="left"
              disabled={ isCreating || newLead.created}
            >
              {newLead.created ? 'Lead Created' : 'Add Lead'}
            </Button>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    newLead: state.lead.newLead
  };
}
export default connect(mapStateToProps)(CreateLead);
