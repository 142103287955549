import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from '../modules/helpers';

import { ActionTypes, STATUS } from '../constants/index';

export const appState = {
  version: "2022-10-25.1",
  alerts: [],
  logs: [],
  zkey: '',
  status: STATUS.READY,
  searchResults: [],
  dispositions: [],
  dispositionsByID: {},
  pauseAutoPull: true,
  coverageAvailable: undefined,
  actionLog:[],
  claimOnLogin: null,
  company:'standard'
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  app: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.alerts = [];
      },
      [ActionTypes.CLAIM_ON_LOGIN]: (draft, { payload }) => {
        draft.claimOnLogin = payload;
      },
      [ActionTypes.NOBLE_CLAIMED]: (draft, { payload }) => {
        draft.claimOnLogin = null;
      },
      [ActionTypes.LOG_MESSAGE]: (draft, { payload }) => {
        draft.logs.push({
          message: payload.message,
          timestamp: new Date().toLocaleTimeString(),
        });
      },
      [ActionTypes.HIDE_ALERT]: (draft, { payload: { id } }) => {
        draft.alerts = draft.alerts.filter(d => d.id !== id);
      },
      [ActionTypes.SHOW_ALERT]: (draft, { payload }) => {
        draft.alerts.push(payload);
      },
      [ActionTypes.SET_ZKEY]: (draft, { payload: { zkey } }) => {
        draft.zkey = zkey;
      },
      [ActionTypes.SEARCH_LEADS]: (draft, { payload }) => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.SUBMIT_EXCEPTION]: (draft, { payload }) => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.LEAD_SEARCH_RESULTS]: (draft, { payload: { searchResults } }) => {
        draft.status = STATUS.READY;
        draft.searchResults = searchResults;
      },
      [ActionTypes.EXCEPTION_RECEIVED]: (draft, { payload: { searchResults } }) => {
        draft.status = STATUS.READY;
      },
      [ActionTypes.SET_COVERAGE_STATUS]: (draft, { payload: { coverageAvailable } }) => {
        draft.coverageAvailable = coverageAvailable;
      },
      [ActionTypes.SET_DISPOSITIONS]: (draft, { payload: { dispositions } }) => {
        draft.dispositions = dispositions;
        draft.dispositionsByID = dispositions.reduce((byID, { id, disposition }) => {
          byID[id] = disposition;
          return byID;
        }, {});
      },
      [ActionTypes.SET_AUTO_PULL]: (draft, { payload }) => {
        draft.pauseAutoPull = payload.pauseAutoPull;
      },
      [ActionTypes.LOG_ACTION]: (draft, { payload }) => {
         if(draft.actionLog.length > 100) {
          draft.actionLog.splice(0,1);
         } 
         draft.actionLog.push(payload);
      },
      [ActionTypes.COMPANY_MODE_RESULT]: (draft, { payload }) => {
        draft.company = payload.companyMode;        
      },  

    },
    appState,
  ),
};
