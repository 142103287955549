import { hot } from 'react-hot-loader/root';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import styled, { css, ThemeProvider } from 'styled-components';


import history from './modules/history';
import theme, { headerHeight } from './modules/theme';
import { utils } from 'styled-minimal';
import 'semantic-ui-css/semantic.min.css';

import Home from './routes/Home';
import Leads from './routes/Leads';
import NotFound from './routes/NotFound';

import Header from './components/Header';
import SystemAlerts from './components/SystemAlerts';

import Footer from './components/Footer';
import GlobalStyles from './components/GlobalStyles';
import RoutePublic from './components/RoutePublic';
import RouteLeads from './components/RouteLeads';
import RouteClaimed from './components/RouteClaimed';
import SessionEnded from './components/SessionEnded';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  opacity: 1 !important;
  position: relative;
  transition: opacity 0.5s;
  
`;

const MainLeads = ({ isAuthenticated }) =>
  isAuthenticated &&
  css`
    padding: ${utils.px(headerHeight)} 0 0;
  `;

const Main = styled.main`
  min-height: 100vh;

  ${MainLeads};
`;

export class App extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  render() {
    const { dispatch, user,standardMode } = this.props;

    return (
      <Router history={history}>
        <ThemeProvider theme={theme}>
          {user.sessionAlive ? null : <SessionEnded />}
          <AppWrapper logged={user.isAuthenticated} style={ standardMode===false ? {backgroundColor:'#7ad0144a'} : {backgroundColor:'#fff'}}>
            {user.isAuthenticated && <Header dispatch={dispatch} user={user} />}
            <Main isAuthenticated={user.isAuthenticated}>
              <Switch>
                <RoutePublic
                  isAuthenticated={user.isAuthenticated}
                  path="/"
                  exact
                  component={Home}
                />
                <RouteLeads
                  isAuthenticated={user.isAuthenticated}
                  path="/leads"
                  component={Leads}
                />
                <RouteClaimed
                  isAuthenticated={user.isAuthenticated}
                  path="/claimed/:number"
                  component={Leads}
                />
                <Route component={NotFound} />
              </Switch>
            </Main>
            <Footer />
            <SystemAlerts />
            <GlobalStyles />
          </AppWrapper>
        </ThemeProvider>
      </Router>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return { 
    user: state.user,
    standardMode : state.app.company==='standard'
  };
}

export default hot(connect(mapStateToProps)(App));
