import React from 'react';
import {Container, Feed, Header} from 'semantic-ui-react'

const styles = {
  container: {
    height: '70vh',
    border: '1px solid #ddd',
    borderRadius: '5px',
    marginTop: '5px',
    overflowY: 'scroll',
    backgroundColor: '#fff'
  },
  header: {
    padding: '10px'
  },
  feed: {
    padding: '10px'
  }
}

const Logs = ({logs}) => (
  <Container style={styles.container}>
    <Header dividing style={styles.header}>Logs</Header>

    <Feed style={styles.feed}>
      {logs.slice(0).reverse().map((log, i) => {
        return <Feed.Event key={`log_${i}`}>
          <Feed.Content>
            <Feed.Summary>
              {log.message}
              <Feed.Date>{log.timestamp}</Feed.Date>
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
      })}
    </Feed>
  </Container>
);

export default Logs;
