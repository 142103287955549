import { showAlert } from '../../actions/app';
import { ActionTypes } from '../../constants/index';

const logMessageHandler = message => ({
  type: ActionTypes.LOG_MESSAGE,
  payload: {
    message,
  },
});

const userMessageHandler = ({ message, type }) => showAlert(message, { variant: type });

const coverageStatusHandler = ({ coverageAvailable }) => ({
  type: ActionTypes.SET_COVERAGE_STATUS,
  payload: {
    coverageAvailable,
  },
});

const forceRefreshHandler = () => {
  window.location.reload(true);
};

const getDispositionsHandler = dispositions => ({
  type: ActionTypes.SET_DISPOSITIONS,
  payload: {
    dispositions,
  },
});

const exceptionReceivedHandler = () => ({
  type: ActionTypes.EXCEPTION_RECEIVED,
  payload: {},
});

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  { event: 'lmessage', handler: logMessageHandler },
  { event: 'user_message', handler: userMessageHandler },
  { event: 'force_refresh', handler: forceRefreshHandler },
  { event: 'coverage_status', handler: coverageStatusHandler },
  { event: 'dispositions', handler: getDispositionsHandler },
  { event: 'exception_received', handler: exceptionReceivedHandler },
];
