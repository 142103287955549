import { ActionTypes, STATUS } from '../../constants/index';

const leadInfoHandler = lead => ({
  type: ActionTypes.NEW_LEAD,
  payload: {
    ...lead,
  },
});

const leadStatusHandler = ({ lead_id, status, message }) => ({
  type: ActionTypes.UPDATE_LEAD,
  payload: {
    id: +lead_id,
    state: status,
    message,
  },
});

const leadBuyerInfoHandler = ({ lead_id, company }) => ({
  type: ActionTypes.UPDATE_LEAD,
  payload: {
    id: +lead_id,
    company,
  },
});

const leadSearchResultsHandler = searchResults => ({
  type: ActionTypes.LEAD_SEARCH_RESULTS,
  payload: {
    searchResults,
  },
});

const dispositionStatusHandler = ({ lead_id, disposition_id, success, message }) => {
  if (success) {
    return {
      type: ActionTypes.UPDATE_LEAD,
      payload: {
        id: +lead_id,
        disposition_id,
        state: 'dispositioned',
        status: STATUS.SUCCESS,
        message,
      },
    };
  } 
  return {
    type: ActionTypes.UPDATE_LEAD,
    payload: {
      id: +lead_id,
      status: STATUS.ERROR,
      message,
    },
  };
};

const recentLeadResponse = ({ recentLeads }) => {
  return {
    type: ActionTypes.RECENT_LEAD_RESPONSE,
    payload: {
      recentLeads
    },
  };
}
const disassociationLeadFinish = ({lead_id})=> {
  return {
    type: ActionTypes.DISASSOCIATION_FINISH,
    payload: {
      lead_id
    }
  }
}
const responseCityStateFromZip = (payload) => {
  return {
    type: ActionTypes.RESPONSE_CITY_STATE_ZIP,
    payload
  }
}


const companyModeReceivedHandler = companyMode => ({
  type: ActionTypes.COMPANY_MODE_RESULT,
  payload: {
    companyMode,
  },
});

const buyerAvailabilityHandler = ({ lead_id, status, message}) => {
  return {
    type: ActionTypes.BUYER_AVAILABILITY_STATUS,
    payload: {
      message,
      status,
      lead_id
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  { event: 'lead_info', handler: leadInfoHandler },
  { event: 'lead_status', handler: leadStatusHandler },
  { event: 'lead_buyer_info', handler: leadBuyerInfoHandler },
  { event: 'lead_search_results', handler: leadSearchResultsHandler },
  { event: 'disposition_status', handler: dispositionStatusHandler },
  { event: 'companymode_received', handler: companyModeReceivedHandler},
  { event: 'recent_lead_response', handler: recentLeadResponse },    
  { event: 'recent_lead_response', handler: recentLeadResponse },
  { event: 'disassociation_lead_finished', handler: disassociationLeadFinish},
  { event: 'responseCityStateFromZip', handler: responseCityStateFromZip},
  { event: 'buyer_availability_status', handler: buyerAvailabilityHandler},
];
