import React from 'react';
import { Modal } from 'semantic-ui-react'

const SessionEnded = () => (
  <Modal open={true} size="large" onMount={()=>{document.title="Rabbit - Session Ended"}}>
    <Modal.Header>Session Has Ended</Modal.Header>
    <Modal.Content>
      <p>Please continue in the new window or refresh the page</p>
    </Modal.Content>
  </Modal>
);

export default SessionEnded;
