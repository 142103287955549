// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/index';

export const { userLogin: login, userLogout: logOut, changeAgentStatus, getAgent } = createActions({
  [ActionTypes.USER_LOGIN]: (creds: Object) => (creds),
  [ActionTypes.USER_LOGOUT]: () => ({}),
  [ActionTypes.CHANGE_AGENT_STATUS]: (status) => ({ status }),
  [ActionTypes.GET_AGENT]: () => ({}),
});
