import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/index';
import { Button, Text } from 'styled-minimal';
import { Form } from 'semantic-ui-react';
import Icon from '../../components/Icon';

import './login.css';

const Login = ({ dispatch, user }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleChangeUsername = e => {
    setUsername(e.target.value);
  };

  const handleChangePassword = e => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login({ username, password }));
  };

  return (
    <Form onSubmit={handleSubmit.bind(this)} className="login-form">
      <Form.Input
        disabled={user.status === 'running'}
        required
        type="text"
        onChange={handleChangeUsername}
        value={username}
        className="input-bottom"
      />
      <Form.Input
        disabled={user.status === 'running'}
        required
        type="password"
        onChange={handleChangePassword}
        value={password}
        className="input-bottom"
      />
      <Button
        animate={user.status === 'running'}
        status={user.status}
        size="lg"
        type="submit"
        textTransform="uppercase"
        data-testid="Login"
        id="login_button"
      >
        <Icon name="sign-in" />
        <Text ml={2}>Login</Text>
      </Button>
    </Form>
  );
};

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps)(Login);
