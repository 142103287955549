import { ActionTypes } from '../../constants/index';

const loginStatusHandler = ({ success, message, tsr, username, userGroupId }) => {
  if (success) {
    return {
      type: ActionTypes.USER_LOGIN_SUCCESS,
      payload: {
        message,
        tsr,
        username,
        userGroupId
      },
    };
  }
  return {
    type: ActionTypes.USER_LOGIN_FAILURE,
    payload: {
      message,
    },
  };
};

const sessionEndedHandler = () => {
  console.log("session has ended")
  return {
    type: ActionTypes.SESSION_ENDED
  }
};

const setSidHandler = uuid => {
  document.cookie = `_sid=${uuid}`;
};

const isAdminHandler = admin => {
  return {
    type: ActionTypes.USER_ROLE,
    payload: {
      isAdmin:true,
    },
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  { event: 'login_status', handler: loginStatusHandler },
  { event: 'set_sid', handler: setSidHandler },
  { event: 'admin', handler: isAdminHandler },
  { event: 'session_ended', handler: sessionEndedHandler }, 
];
