import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Label, Table } from 'semantic-ui-react';
import { requestCoverage } from '../actions';


class CoverageStatus extends React.PureComponent {
  constructor(props) { 
      super(props)
      this.state = { loading:false, intervalRunning:false, coverageStatusText : '' }
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  componentDidUpdate(prevProps) {
    this.setState({loading:false})
    if(prevProps.coverageAvailable !== this.props.coverageAvailable) {
      if(this.props.coverageAvailable) {
         this.setState({coverageStatusText:""})
      }else{
        this.coverageLoop();
      }
    }
  }

  coverageLoop = () => {
    if(!this.state.intervalRunning) {
      let counter;
      this.setState({loading:true, coverageStatusText: 'Checking Coverage...'})
      const loop = () => {
        const { dispatch , coverageAvailable } = this.props;
        if(coverageAvailable === false) {
          this.setState({coverageStatusText:'No Coverage'})
          setTimeout(function(){
            this.setState({coverageStatusText:'Checking Coverage...'})
            dispatch(requestCoverage());
          }.bind(this),1000);
        }else{
          this.setState({coverageStatusText:'', loading:false, intervalRunning:false})
          clearInterval(counter)
        }
    }
    loop();
    counter =  setInterval(loop,3000)
  }
  
}

  render() {
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
             <Table.HeaderCell textAlign='center'>Coverage</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell textAlign='center'>  
              {this.state.coverageStatusText.length > 0 ? (this.state.coverageStatusText === 'No Coverage') ?
                <Button as='div' labelPosition='right'>
                    <Button as='div' color='red'><i className="thumbs down outline icon"></i></Button>
                    <Label as='a' basic color='red' pointing='left'>No Coverage</Label>  
                </Button> : 
                <Button as='div' labelPosition='right'>
                    <Button as='div' color='yellow'><i className="search icon"></i></Button>
                    <Label as='a' basic color='yellow' pointing='left'>{this.state.coverageStatusText}</Label>  
                </Button> : 
                this.props.coverageAvailable ? <Button as='div' labelPosition='right'>
                    <Button as='div' color='green'><i className="thumbs up outline icon"></i></Button>
                    <Label as='a' basic color='green' pointing='left'>Leads Available</Label> 
                    </Button> :
                <Button as='div' labelPosition='right'>
                    <Button as='div' color='red'><i className="thumbs down outline icon"></i></Button>
                    <Label as='a' basic color='red' pointing='left'>Leads Available</Label>
                </Button>}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

function mapStateToProps(state) {
  return {
    coverageAvailable: state.app.coverageAvailable,
    loading: state.lead.status === "running",
  };
}

export default connect(mapStateToProps)(CoverageStatus);
