import React from 'react';
import styled from 'styled-components';

import { Container } from 'styled-minimal';

const FooterWrapper = styled.footer`
  border-top: 0.1rem solid #ddd;
  display: fixed;
  bottom: 0px;
`;

const Footer = () => (
  <FooterWrapper>
    <Container py={3}>© {new Date().getFullYear()} Equate Media</Container>
  </FooterWrapper>
);

export default Footer;
