/**
 * @module Sagas/User
 * @desc User
 */

import { all, apply, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { ActionTypes } from '../constants/index';

import { socket } from '../modules/socket';

/**
 * Login
 */
export function* login({ type, payload: creds }) {
  try {
    yield apply(socket, socket.emit, ['login', creds]);
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: 'sagas/user login()',
      },
    });
    yield put({
      type: ActionTypes.USER_LOGIN_FAILURE,
      payload: err,
    });
  }
}

/**
 * Logout
 */
export function* logout({ type }) {
  try {
    document.cookie = '_sid=null';
    yield fetch('/logout', {
      method: 'POST',
    });
    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS,
    });
    yield apply(socket, socket.emit, ['logout']);
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: 'sagas/user logout()',
      },
    });
    yield put({
      type: ActionTypes.USER_LOGOUT_FAILURE,
      payload: err,
    });
  }
}

export function* showUserLoginFailed({ payload: error }) {
  yield put({
    type: ActionTypes.SHOW_ALERT,
    payload: {
      message: error.message,
      position: 'bottom-right',
      timeout: 5,
      variant: 'danger',
    },
  });
}

export function* getRecentLeads({type}) {
  try {
    yield apply(socket, socket.emit, ['recent_leads']);
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: 'sagas/user getRecentLeads()',
      },
    });
  }
}

export function* changeAgentStatus({ payload: { status }}){
  try {
    yield apply(socket, socket.emit, ['change_agent_status', { status }]);
  } catch (err){
    console.log(err);
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        status,
        func: 'sagas/user changeAgentStatus()',
      },
    });
  }
}

export function* getAgent() {
  try {
    yield apply(socket, socket.emit, ['get_agent']);
  } catch (err) {
    console.log(err);
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type: ActionTypes.GET_AGENT,
        func: 'sagas/user getAgent()',
      },
    });
  }
}
/**
 * User Sagas
 */
export default function* root() {
  yield all([
    takeLeading(ActionTypes.USER_LOGIN, login),
    takeLatest(ActionTypes.USER_LOGOUT, logout),
    takeLatest(ActionTypes.USER_LOGIN_FAILURE, showUserLoginFailed),
    takeLatest(ActionTypes.GET_RECENT_LEADS, getRecentLeads),
    takeLatest(ActionTypes.CHANGE_AGENT_STATUS, changeAgentStatus),
    takeLatest(ActionTypes.GET_AGENT, getAgent),
  ]);
}
