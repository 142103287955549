import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { appColor, headerHeight } from '../modules/theme';

import { logOut } from '../actions';

import { Container, utils } from 'styled-minimal';
import Icon from './Icon';
import Search from '../containers/Search';

import { Loader } from 'semantic-ui-react';

const { responsive, spacer } = utils;

const HeaderWrapper = styled.header`
  background-color: #113740;
  height: ${headerHeight}px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 200;

  &:before {
    background-color: ${appColor};
    bottom: 0;
    content: '';
    height: 0.2rem;
    left: 0;
    position: absolute;
    right: 0;
  }
`;

const HeaderContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  padding-bottom: ${spacer(2)};
  padding-top: ${spacer(2)};
`;

const Logout = styled.button`
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 1.3rem;
  padding: ${spacer(2)};

  ${responsive({ lg: 'font-size: 1.6rem;' })}; /* stylelint-disable-line */

  &.active {
    color: #fff;
  }

  span {
    display: inline-block;
    margin-right: 0.4rem;
    text-transform: uppercase;
  }
`;

const Label = styled.span`
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 1.3rem;
  padding: ${spacer(2)};
  text-transform: capitalize;

  ${responsive({ lg: 'font-size: 1.6rem;' })};
`;


export  class Header extends React.PureComponent {
  state = {
    loading: false
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  handleClickLogout = e => {
    e.preventDefault();
    const { dispatch } = this.props;
    this.setState({loading: true})
    dispatch(logOut());
    setTimeout(()=>{
      this.setState({loading: false})
    },15000)
  };

  render() {
    const { companyMode, version } = this.props;
    const {loading} = this.state
    return (
      <HeaderWrapper>
        <HeaderContainer>
          <Search />
          <Label>{companyMode}</Label>
          {loading ? <Loader style={{left: '95%'}} active />  : <Logout onClick={this.handleClickLogout}>
            <span>logout</span>
            <Icon name="sign-out" width={16} />
          </Logout>}
        </HeaderContainer>
        <span style={{position: 'fixed', right: 10, top: 40, color: 'white'}}>v{version}</span>
      </HeaderWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    companyMode: state.app.company,
    version: state.app.version,
  };
}

export default connect(mapStateToProps)(Header);
