import { handleActions } from '../modules/helpers';

import { STATUS, ActionTypes } from '../constants/index';

export const userState = {
  isAuthenticated: false,
  status: STATUS.IDLE,
  username: '',
  sessionAlive: true,
  recentLeads : [],
  agentStatus: 'ONLINE'
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  user: handleActions(
    {
      [ActionTypes.USER_LOGIN]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.USER_LOGIN_SUCCESS]: (draft, { payload: { tsr, username, userGroupId } }) => {
        draft.isAuthenticated = true;
        draft.status = STATUS.READY;
        draft.tsr = tsr;
        draft.username = username;
        draft.userGroupId = userGroupId;

      },
      [ActionTypes.USER_LOGIN_FAILURE]: draft => {
        draft.isAuthenticated = false;
        draft.status = STATUS.IDLE;
      },
      [ActionTypes.USER_LOGOUT]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.USER_LOGOUT_SUCCESS]: draft => {
        draft.isAuthenticated = false;
        draft.status = STATUS.IDLE;
      },
      [ActionTypes.SESSION_ENDED]: draft => {
        draft.sessionAlive = false;
      },
      [ActionTypes.RECENT_LEAD_RESPONSE]: (draft, { payload: {recentLeads} }) => {
        draft.recentLeads = recentLeads;
      },
      [ActionTypes.USER_ROLE]: (draft, { payload: {isAdmin} }) => {
        draft.isAdmin = isAdmin;
      },
      [ActionTypes.GET_AGENT]: (draft) => {
        draft.agentStatus = 'ONLINE';
      },
      [ActionTypes.AGENT_STATUS]: (draft, { payload: { status }}) => {
        draft.agentStatus = status;
      }

    },
    userState,
  ),
};
