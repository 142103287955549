import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Screen } from 'styled-minimal';
import { Grid } from 'semantic-ui-react';
import Lead from '../containers/Lead';
import Exception from '../containers/Exception';
import LeadOperations from '../components/LeadOperations';
import Loader from '../components/Loader';
import Logs from '../components/Logs';
import CoverageStatus from '../components/CoverageStatus'
import RecentLeads from '../components/RecentLeads'
export class Leads extends React.PureComponent {
  static propTypes = {
    lead: PropTypes.object.isRequired,
    logs: PropTypes.array.isRequired,
    dispositionsByID: PropTypes.object.isRequired,
  };

  render() {
    const { dispositionsByID, lead, logs, coverageAvailable } = this.props;

    return (
      <Screen key="Leads" data-testid="LeadsWrapper">
        <Container verticalPadding style={{ maxWidth: '90%' }}>
          <Grid>
            <Grid.Row columns={3}>
              <Grid.Column width={12} style={{backgroundColor:'#fff', padding: '10px'}}>
                <LeadOperations />
                {lead.status === 'running' && <Loader size={100} block />}
                {lead.id && <Lead dispositionsByID={dispositionsByID} />}
              </Grid.Column>
              <Grid.Column width={4}>
                {/* <InboundCallManagement />*/}
                <Exception id="exception-button" />
                <CoverageStatus coverageAvailable={coverageAvailable} />
                <RecentLeads />
                <Logs logs={logs} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Screen>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    lead: state.lead.currentLead,
    logs: state.app.logs,
    dispositionsByID: state.app.dispositionsByID,
    coverageAvailable : state.app.coverageAvailable
  };
}

export default connect(mapStateToProps)(Leads);
