// @flow
/**
 * @module Actions/App
 * @desc App Actions
 */

import { nanoid } from 'nanoid';
import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/index';

export { goBack, go, push, replace } from '../modules/history';

export const {
  hideAlert,
  showAlert,
  searchLeads,
  submitException,
  setAutoPull,
  getRecentLeads,
  companyMode,
  getCache,
} = createActions({
  [ActionTypes.GET_CACHE]: () => ({}),
  [ActionTypes.HIDE_ALERT]: (id: string) => ({ id }),
  [ActionTypes.SHOW_ALERT]: (message: string, options: Object) => {
    const timeout = options.variant === 'danger' ? 0 : 5;
    return {
      id: options.id || nanoid(),
      icon: options.icon,
      message,
      position: options.position || 'bottom-right',
      variant: options.variant || 'dark',
      timeout: typeof options.timeout === 'number' ? options.timeout : timeout,
    };
  },
  [ActionTypes.SEARCH_LEADS]: (query: string) => ({ query }),
  [ActionTypes.SUBMIT_EXCEPTION]: (description: string) => ({ description }),
  [ActionTypes.SET_AUTO_PULL]: (pauseAutoPull: boolean) => ({
    pauseAutoPull,
  }),
  [ActionTypes.SET_COVERAGE_STATUS]: (coverageAvailable: boolean) => ({
    coverageAvailable,
  }),
  [ActionTypes.COMPANY_MODE]: (companyMode: string) => ({
    companyMode,
  }),
  [ActionTypes.GET_RECENT_LEADS]: () => ({}),
});
