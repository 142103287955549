import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Container, Form, Message } from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { dispositionLead } from '../../actions/index';
import './DispositionManagement.css';
export class DispositionManagement extends React.PureComponent {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    dispositions: PropTypes.array.isRequired,
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.leadState  !== this.props.leadState || this.props.leadState === "dispositioned") {
      this.setState({loading:false, value:-1})
    }
  }

  state = {
    value: -1,
    callbackTime: moment().format('YYYY-MM-DD HH:mm:ss'),
    loading:false,
    callbackTimeError:false,
    showDispositionWarning: false
  };

  handleResultSelect = (e, { value }) => {
    this.setState({
      value,
      setDispError: false,
      showDispositionWarning: false,
    });
  };

  setDisposition = id => {
    const { dispatch, dispositionsByID } = this.props;

    if (!id) {
      this.setState({ setDispError: true });
    } else {
      dispatch(dispositionLead(id, dispositionsByID[id], this.state.callbackTime));
    }
  };

  handleSubmit = () => {
    const { value } = this.state;
    if (value !== -1) {
      this.setState({ loading: true });
      this.setDisposition(value);
    } else {
      this.setState({ showDispositionWarning: true });
    }
  };

  handleCallbackChange = (event, { value }) => {
    if(value.length > 0) {
        const isValid = !moment(value).isAfter(moment());
        if(isValid) {
          this.setState({
            callbackTimeError:true,
          })
        }else{
          this.setState({
            callbackTimeError:false,
          })
        }
    }

    this.setState({
      callbackTime: value,
    });
  };

  render() {
    const { dispositions, dispositionsByID, leadState, loading, leadDialed, leadSearched, appPauseAutoPull, callState, usesNoble } = this.props;
    const { showDispositionWarning } = this.state;
    
    // eslint-disable-next-line no-mixed-operators
    const dispositionDisabled = ['dispositioned', 'transferred'].includes(leadState) || (callState !== 'error_connecting') && (leadSearched !== true && leadDialed === false) || ( !usesNoble && (appPauseAutoPull === false && callState !=='hangup'))

    return (
      <Container id="disposition-management-container">
        <Form loading={loading}>
          <Form.Group inline id="disp-buttons">
            <Form.Select
              disabled={dispositionDisabled}
              id="disposition-dropdown"
              options={dispositions
                .filter(({ visible, quick_disp }) => visible && !quick_disp)
                .map(({ id, disposition }) => ({
                  text: disposition,
                  value: id,
                })).sort((a,b)=>{
                  return a.text.localeCompare(b.text);
                })}
              error={this.state.setDispError}
              onChange={this.handleResultSelect}
              value={this.state.value}
              placeholder="Select a Disposition"
            />

            {dispositionsByID[this.state.value] &&
              dispositionsByID[this.state.value].toLowerCase().includes('call back') && (
                
                <DateTimeInput
                  disabled={dispositionDisabled}
                  onChange={this.handleCallbackChange}
                  error={this.state.callbackTimeError}
                  value={this.state.callbackTime}
                  dateFormat="YYYY-MM-DD"
                  animation={false}
                />
              )}

            <Form.Button
              disabled={dispositionDisabled || this.state.loading || this.state.callbackTimeError}
              id="submit-button"
              onClick={this.handleSubmit.bind(this)}
              loading={this.state.loading}
            >
              Submit
            </Form.Button>

            <Form.Group inline id="quick-dispositions">
              {dispositions
                .filter(({ quick_disp }) => quick_disp)
                .map(({ id, disposition }, i) => (
                  <Form.Button
                    disabled={dispositionDisabled || this.state.loading}
                    onClick={() => {
                      this.setState({loading:true})
                      this.setDisposition(id, disposition);
                    }}
                    key={`quick_disp_${i}`}
                    loading={this.state.loading}
                  >
                    {disposition}
                  </Form.Button>
                ))}
            </Form.Group>
          </Form.Group>
          <Form.Group>
            {showDispositionWarning && (<Message negative content='Please select a Disposition before submitting'/>)}
          </Form.Group>
        </Form>
      </Container>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    dispositions: state.app.dispositions,
    dispositionsByID: state.app.dispositionsByID,
    loading: state.app.status === 'running' || state.lead.currentLead.status === 'running',
    leadState: state.lead.currentLead.state,
    leadDialed: state.lead.currentLead.dialed,
    leadSearched: state.lead.currentLead.searched,
    appPauseAutoPull : state.app.pauseAutoPull,
    callState : state.call.state,
    usesNoble: state.user.tsr > 0,
  };
}

export default connect(mapStateToProps)(DispositionManagement);
