import { ActionTypes } from '../../constants/index';

const agentStatus = ({ status }) => {
    return {
        type: ActionTypes.AGENT_STATUS,
        payload: { status }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    { event: 'agent_status', handler: agentStatus },
]
