// @flow
/**
 * @module Actions/Lead
 * @desc Lead Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/index';

export const {
  requestLead, createLead,
  resetNewLead, clearTempLead,
  resetTempLead, dispositionLead,
  editLead, requestCoverage,
  disassociateLead, fetchCityStateFromZip,
  checkBuyerAvailability, buyerAvailabilityStatus
} = createActions({
  [ActionTypes.REQUEST_LEAD]: (id: number) => ({ id }),
  [ActionTypes.CREATE_LEAD]: (lead: Object) => lead,
  [ActionTypes.RESET_NEW_LEAD]: () => ({}),
  [ActionTypes.CLEAR_TEMP_LEAD]: () => ({}),
  [ActionTypes.RESET_TEMP_LEAD]: () => ({}),
  [ActionTypes.UPDATE_LEAD]: (lead: Object) => ({ lead }),
  [ActionTypes.DISPOSITION_LEAD]: (disp_id: number, disp_text: string, callback_time: string) => ({
    disp_id,
    disp_text,
    callback_time,
  }),
  [ActionTypes.EDIT_LEAD]: (lead: Object) => lead,
  [ActionTypes.REQUEST_COVERAGE]: () => ({}),
  [ActionTypes.FETCH_CITY_STATE_FROM_ZIP]:(zip: number, codeType: string, mode: string) => ({zip, codeType, mode}),
  [ActionTypes.DISASSOCIATE_LEAD]: (id: number) => ({ id }),
  [ActionTypes.CHECK_BUYER_AVAILABILITY]: ({ leadId, toCompany }) => ({ leadId, toCompany }),
  [ActionTypes.BUYER_AVAILABILITY_STATUS]: (status) => ({ status }),
});
