import { ActionTypes } from '../constants/index'
import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';
import { createSocketChannel, socket } from '../modules/socket';
import user from './user';
import lead from './lead';
import calls from './call';

function* watchSocketEvents() {
  try {
    const socketChannel = yield call(createSocketChannel, socket);
    while (true) {
      const socketEventAction = yield take(socketChannel);
      yield put(socketEventAction);
    }
  } catch (err) {
    console.error('socket error:', err);
  }
}


function* watchAndLog() {
  yield takeEvery("*", function* logger(action) {
    try {
      if(action.type !== "LOG_ACTION") {
        // console.log(action)
        yield put({ type:ActionTypes.LOG_ACTION, payload:{ ...action, timestamp: new Date() }});
      }
    } catch (e) {
      console.log(`error ${e}`)
    }
  })
}

function* getServerCache() {
  // eslint-disable-next-line require-yield
  yield takeEvery("GET_CACHE", function* logger(action) {
    try {
      socket.emit("get_cache")
    } catch (e) {
      console.log(`error ${e}`)
    }
  })
}

/**
 * rootSaga
 */
export default function* root() {
  yield all([fork(user), fork(lead), fork(calls), fork(watchSocketEvents), fork(watchAndLog), fork(getServerCache)]);
}
