import io from 'socket.io-client';

import { eventChannel, buffers } from 'redux-saga';

import authEvents from './auth';
import leadEvents from './lead';
import miscEvents from './misc';
import callEvents from './call';
import userEvents from './user';

const socketEvents = authEvents.concat(leadEvents, miscEvents, callEvents, userEvents);

const socket = io.connect();

function createSocketChannel(_socket) {
  return eventChannel(emit => {
    const errorHandler = errorEvent => {
      emit(new Error(errorEvent.reason));
    };

    socketEvents.forEach(({ event, handler }) => {
      _socket.on(event, data => {
        const action = handler(data);
        if (action) {
          emit(action);
        }
      });
    });

    _socket.on('message', console.log);
    _socket.on('error', errorHandler);

    const unsubscribe = () => {
      socketEvents.forEach(({ event, handler }) => {
        _socket.off(event, handler);
      });
    };

    return unsubscribe;
  }, buffers.expanding(5));
}

export { socket, createSocketChannel };
