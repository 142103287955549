import React from 'react';
import styled from 'styled-components';
import config from '../config';
import { Container, utils } from 'styled-minimal';
import Login from '../containers/Login'
import Background from '../components/Background';

const { spacer } = utils;
const HomeContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;

const Heading = styled.h1`
  color: #fff;
  font-size: 3.5rem;
  line-height: 1.4;
  margin-bottom: ${spacer(3)};
  margin-top: 0;
  text-align: center;

  ${/* sc-custom '@media-query' */ utils.responsive({
    lg: `
      font-size: 4rem;
    `,
  })};
`;

export class Home extends React.PureComponent {
  render() {

    return (
      <Background key="Home" data-testid="HomeWrapper">
        <HomeContainer verticalPadding>
          <Heading>{config.description}</Heading>
          <Login />
        </HomeContainer>
      </Background>
    );
  }
}

export default Home;
