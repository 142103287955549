import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Container } from 'styled-minimal';
import { Grid } from 'semantic-ui-react';
import CallManagement from '../CallManagement';
import DispositionManagement from '../DispositionManagement';
import DispositionHistory from '../../components/DispositionHistory';
import LeadInfo from '../../components/LeadInfo';


const LeadContainer = styled(Container)`
  align-items: left;
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Label = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  position: center;
  padding: 20px;
  font-weight: 400;
  line-height: 32px;
  `;

export class Lead extends React.PureComponent {
  static propTypes = {
    lead: PropTypes.object.isRequired
  };

  render() {
    const { currentLead: { openDate, dispositions, dialed, searched }, dispositionsByID } = this.props
    console.log(dialed, searched)
    return (
      <LeadContainer>
        <LeadInfo/>
        <CallManagement/>
        <DispositionManagement/>
        <Grid.Row>
        { dialed || searched ? 
          <Grid.Column width={16}>
            <DispositionHistory
              dispositions={dispositions}
              dispositionsByID={dispositionsByID}
              openDate={openDate}
            />
          </Grid.Column>
         :
          <Grid.Column width={16}>
            <Label>Lead history will be shown on dial</Label>
          </Grid.Column>
        }
      </Grid.Row>
      </LeadContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentLead: state.lead.currentLead
  };
}

export default connect(mapStateToProps)(Lead);
