import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { requestLead } from '../actions';

class RecentLeads extends React.PureComponent {

  render() {
    const { recentLeads, dispatch, userIsAdmin } = this.props;
    const recentLeadLists = [];
    if (recentLeads !== undefined && recentLeads !== null) {
      const recentArray = Object.values(recentLeads);
      recentArray.reverse().forEach(item => {
        recentLeadLists.push(
          <Table.Row key={`recent_lead_${item.lead_id}`}>
            <Table.Cell
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if(userIsAdmin){
                  dispatch(requestLead(item.lead_id));
                }
              }}
            >
              <span style={{ color: '#607695', fontWeight: 'bold' }}>#{item.lead_id}</span>
            </Table.Cell>
            <Table.Cell>{item.name}</Table.Cell>
            <Table.Cell>{item.status}</Table.Cell>
          </Table.Row>,
        );
      });
    }

    return (
      <div>
        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Recent Leads</div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {recentLeadLists.length > 0 ? recentLeadLists : <Table.Row></Table.Row>}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    recentLeads: state.user.recentLeads,
    userIsAdmin: state.user.isAdmin
  };
}

export default connect(mapStateToProps)(RecentLeads);
