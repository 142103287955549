import { ActionTypes } from '../../constants/index';

const callStatusHandler = ({callUUID, lead_id, status, message}) => {
  return {
    type: ActionTypes.CALL_STATUS,
    payload: {
      callUUID,
      lead_id,
      state: status,
      message,
    }
  }
}

const inboundCallHandler = ({callUUID, source}) => {
  return {
    type: ActionTypes.INBOUND_CALL,
    payload: {
      available: true,
      callUUID,
      source,
      call_time: new Date()
    }
  }
}

const inboundClaimedHandler = ({phone, name, source, callUUID}) => {
  return {
    type: ActionTypes.INBOUND_CLAIMED,
    payload: {
      callUUID,
      phone,
      source,
      name
    }
  }
}

const inboundUnavailableHandler = ({callUUID}) => {
  return {
    type: ActionTypes.INBOUND_UNAVAILABLE,
    payload: {
      available: false,
      callUUID,
      claimed_time: new Date()
    }
  }
}

const hangupReceivedHandler = () => ({
  type: ActionTypes.HANGUP_RECEIVED,
  payload: {},
});

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {event: 'call_status', handler: callStatusHandler},
  {event: 'inbound_call', handler: inboundCallHandler},
  {event: 'inbound_claimed', handler: inboundClaimedHandler},
  {event: 'inbound_unavailable', handler: inboundUnavailableHandler},
  {event: 'hangup_received', handler: hangupReceivedHandler}
]
