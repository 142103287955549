/**
 * @module Sagas/Lead
 * @desc Lead
 */

import {
  all,
  apply,
  put,
  select,
  takeLatest,
  } from 'redux-saga/effects';
import { ActionTypes } from '../constants/index';
import { socket } from '../modules/socket';
import * as selectors from './selectors';

export function* requestLead({ type, payload: { id } }) {
  try {
    yield apply(socket, socket.emit, ['get_lead', id || 0]);
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/lead requestLead()",
      },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to get lead',
        options: { variant: 'failure', icon: 'alert' },
      },
    });
  }
}

export function* createLead({ type, payload: lead }) {
  try {
    yield apply(socket, socket.emit, ['create_lead', lead]);
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/lead createLead()",
      },
    });

    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to create lead',
        options: { variant: 'failure', icon: 'alert' },
      },
    });

    yield put({
      type: ActionTypes.CREATE_LEAD_ERROR,
      payload: {
        err,
        type,
        func: "sagas/lead createLead()",
      },
    });
  }
}

export function* editLead({ type, payload: lead }) {
  try {
    yield apply(socket, socket.emit, ['edit_lead', lead]);
  } catch (err) {
    console.log(err);
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/lead editLead()",
      },
    });

    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to edit lead',
        options: { variant: 'failure', icon: 'alert' },
      },
    });
  }
}

export function* autoDialLead({type}) {
  try {
    const pauseAutoPull = yield select(selectors.pauseAutoPull)
    const leadState = yield select(selectors.leadState)
    const leadSearched = yield select(selectors.leadSearched)
    if ( !pauseAutoPull && !leadSearched && leadState === "pulled" ) {
      yield put({
        type: ActionTypes.DIAL_LEAD,
      });
    }
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/lead autoDialLead()",
      },
    });

    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to auto-dial lead',
        options: { variant: 'failure', icon: 'alert' },
      },
    });
  }
}

export function* autoPullLead({type, payload: { state } }) {
  try {
    if ((yield select(selectors.pauseAutoPull)) !== true && ['dispositioned','transferred'].includes(state)) {
      yield put({
        type: ActionTypes.REQUEST_LEAD,
        payload: {},
      });
    }
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/lead autoPullLead()",
      },
    });

    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to auto-pull lead',
        options: { variant: 'failure', icon: 'alert' },
      },
    });
  }
}

export function* dispositionHangup({type}) {
  try {
    yield apply(socket, socket.emit, ['end_call', 'terminate'])
  } catch (err) {
    console.log(err)
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/dispositionHangup()",
      },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to dispositionHangup call', 
        options: { variant: 'failure', icon: 'alert' }
      }
    })
  }
}

export function* dispositionLead({type, payload: { disp_id, disp_text, callback_time } }) {
  try {
    yield apply(socket, socket.emit, [
      'set_disposition',
      yield select(selectors.leadId),
      disp_id,
      disp_text,
      callback_time,
    ]);
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/lead dispositionLead()",
      },
    });

    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to get lead',
        options: { variant: 'failure', icon: 'alert' },
      },
    });
  }
}

export function* searchLeads({ type, payload: { query } }) {
  try {
    yield apply(socket, socket.emit, ['lead_search', query]);
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/lead searchLeads()",
      },
    });

    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to get lead',
        options: { variant: 'failure', icon: 'alert' },
      },
    });
  }
}

export function* submitException({type, payload: { description } }) {
  try {
    yield apply(socket, socket.emit, [
      'exception_report',
      {
        description,
        lead_id: yield select(selectors.leadId),
        username: yield select(selectors.username),
      },
    ]);
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/lead submitException()",
      },
    });

    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to get lead',
        options: { variant: 'failure', icon: 'alert' },
      },
    });
  }
}

export function* requestCoverage({type}) {
  try {
    yield apply(socket, socket.emit, ['coverage_status']);
  } catch (err) {
    console.log(err);
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/lead requestCoverage()",
      },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to get coverage',
        options: { variant: 'failure', icon: 'alert' },
      },
    });
  }
}

export function* companyModeHandle({type, payload: {companyMode}}) {
  try {
    yield apply(socket, socket.emit, ['company_mode',
      {
        companyMode
      },
    ]);
  } catch (err) {
  console.log(err);
  /* istanbul ignore next */
  yield put({
    type: ActionTypes.LOG_ERROR,
    payload: {
      err,
      type,
       func: "sagas/lead companyMode()",
      },
    });
  yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to set company mode',
        options: { variant: 'failure', icon: 'alert' },
      },
    });
  }
}

export function* disassociateLead({type, payload: {id}}) {
  try {
    yield apply(socket, socket.emit, ['disassociation_lead', id]);
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/lead disassociateLead()",
      },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to disassociate lead',
        options: { variant: 'failure', icon: 'alert' },
      },
    });
  }
}

export function* fetchCityStateFromzip({type, payload: {zip, codeType, mode}}) {
  try {
    yield apply(socket, socket.emit, ['fetch_city_state_from_zip', {zip, codeType, mode}]);
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/lead fetchCityStateFromzip()",
      },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to get lead',
        options: { variant: 'failure', icon: 'alert' },
      },
    });
  }
}

export function* checkBuyerAvailability({ type, payload: { leadId, toCompany } }) {
  try {
    yield apply(socket, socket.emit, ['check_buyer_availability', { leadId, toCompany }]);

  } catch (err) {

    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/lead checkLeadBuyerAvailability()",
      },
    });

    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to check lead buyer availability',
        options: { variant: 'failure', icon: 'alert' },
      },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.REQUEST_LEAD, requestLead),
    takeLatest(ActionTypes.CREATE_LEAD, createLead),
    takeLatest(ActionTypes.SEARCH_LEADS, searchLeads),
    takeLatest(ActionTypes.SUBMIT_EXCEPTION, submitException),
    takeLatest(ActionTypes.DISPOSITION_LEAD, dispositionLead),
    takeLatest(ActionTypes.DISPOSITION_LEAD, dispositionHangup),
    takeLatest(ActionTypes.UPDATE_LEAD, autoPullLead),
    takeLatest(ActionTypes.EDIT_LEAD, editLead),
    takeLatest(ActionTypes.REQUEST_COVERAGE, requestCoverage),
    takeLatest(ActionTypes.NEW_LEAD, autoDialLead),
    takeLatest(ActionTypes.FETCH_CITY_STATE_FROM_ZIP, fetchCityStateFromzip),
    takeLatest(ActionTypes.COMPANY_MODE, companyModeHandle),
    takeLatest(ActionTypes.DISASSOCIATE_LEAD, disassociateLead),
    takeLatest(ActionTypes.CHECK_BUYER_AVAILABILITY, checkBuyerAvailability),
  ]);
}
