import { handleActions } from '../modules/helpers';

import { STATUS, ActionTypes } from '../constants/index';

export const callState = {
  status: STATUS.IDLE,
  state: 'hangup',
  inboundCallQueue: [{
    available: true,
    source: "BVL",
    call_time: new Date(),
  }]
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  call: handleActions(
    {
      [ActionTypes.CALL_STATUS]: (draft, { payload }) => {
        draft.status = STATUS.READY;
        Object.assign(draft, payload)
      },
      [ActionTypes.INBOUND_CALL]: (draft, { payload }) => {
        draft.status = STATUS.READY;
        draft.inboundCallQueue = draft.inboundCallQueue.concat([ payload ])
      },
      [ActionTypes.INBOUND_CLAIMED]: (draft, { payload }) => {
        draft.status = STATUS.READY;
        draft.inboundCallQueue = draft.inboundCallQueue.filter((call)=>{
          return call.callUUID !== payload.callUUID
        })
      },
      [ActionTypes.INBOUND_UNAVAILABLE]: (draft, { payload }) => {
        draft.status = STATUS.READY;
        draft.inboundCallQueue = draft.inboundCallQueue.filter((call)=>{
          return call.callUUID !== payload.callUUID
        })
      },
      [ActionTypes.HANGUP_RECEIVED]: draft => {
        draft.hangup_update = new Date()
      },
    },
    callState,
  ),
};

