import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import treeChanges from 'tree-changes';

import { Button,Icon, Modal, TextArea } from 'semantic-ui-react';

import { showAlert, submitException } from '../../actions/index';

import './Exception.css';

export class Exception extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { checked: true };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  handleSubmit = () => {
    this.props.dispatch(
      submitException(`${this.state.value}
      Redux State:
      ${JSON.stringify(this.props.allState)}
    `),
    );
  };

  componentDidUpdate(prevProps) {
    const { dispatch } = this.props;
    const { changedTo } = treeChanges(prevProps, this.props);

    if (changedTo('loading', false) && document.getElementsByClassName('close icon')[0]) {
      document.getElementsByClassName('close icon')[0].click();
      dispatch(showAlert('Exception Received', { variant: 'success' }));
    }
  }

  render() {
    const { loading } = this.props;

    return (
      <Modal
        trigger={
          <Button id="exception-button">
            <Icon name="exclamation triangle" /> Exception
          </Button>
        }
        closeIcon
      >
        <Modal.Header>Report An Exception</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>Please describe the issue:</p>
            <TextArea
              disabled={loading}
              id="exception-description"
              onChange={({ target: { value } }) => {
                this.setState({ value });
              }}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleSubmit} disabled={loading} loading={loading}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    allState: state,
    loading: state.app.status === 'running',
  };
}

export default connect(mapStateToProps)(Exception);
