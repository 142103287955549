import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { nobleClaimed } from '../actions/call'

export class RouteClaimed extends React.PureComponent {
  componentDidMount(){
    try{
      const { dispatch, computedMatch } = this.props;
      dispatch(nobleClaimed(computedMatch.params.number));
    }catch(e){
      console.log(e);
    }
  }
  
  render() {
    const { component: Component, isAuthenticated, to, ...rest } = this.props;
    return <Route
      {...rest}
      render={props =>
        <Redirect
          to={{
            pathname: to,
            state: { redirect: props.location.pathname, isAuthenticated },
          }}
        />
      }
    />;
  };
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(RouteClaimed);