import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Modal } from 'semantic-ui-react';
import { editLead, clearTempLead, resetTempLead, fetchCityStateFromZip } from '../../actions/lead';
import { Input, Select, Form } from 'formsy-semantic-ui-react';
import { addValidationRule } from 'formsy-react';

import './EditLead.css';

const moveSizes = [
  {
    val_to_submit: 'studio',
    text: 'studio',
    value: 'studio',
  },
  {
    val_to_submit: 'one bed apartment',
    text: 'one bed apartment',
    value: 'one bed apartment',
  },
  {
    val_to_submit: 'one bed house',
    text: 'one bed house',
    value: 'one bed house',
  },
  {
    val_to_submit: 'two bed apartment',
    text: 'two bed apartment',
    value: 'two bed apartment',
  },
  {
    val_to_submit: 'two bed house',
    text: 'two bed house',
    value: 'two bed house',
  },
  {
    val_to_submit: 'three bed apartment',
    text: 'three bed apartment',
    value: 'three bed apartment',
  },
  {
    val_to_submit: 'three bed house',
    text: 'three bed house',
    value: 'three bed house',
  },
  {
    val_to_submit: 'four bed apartment',
    text: 'four bed apartment',
    value: 'four bed apartment',
  },
  {
    val_to_submit: 'four bed house',
    text: 'four bed house',
    value: 'four bed house',
  },
  {
    val_to_submit: 'five bed apartment and more',
    text: 'five bed apartment and more',
    value: 'five bed apartment and more',
  },
  {
    val_to_submit: 'five bed house and more',
    text: 'five bed house and more',
    value: 'five bed house and more',
  },
  {
    val_to_submit: 'commercial',
    text: 'commercial',
    value: 'commercial',
  },
];

const validStates = {
  AL: true,
  AK: true,
  AZ: true,
  AR: true,
  CA: true,
  CO: true,
  CT: true,
  DC: true,
  DE: true,
  FL: true,
  GA: true,
  HI: true,
  ID: true,
  IL: true,
  IN: true,
  IA: true,
  KS: true,
  KY: true,
  LA: true,
  ME: true,
  MD: true,
  MA: true,
  MI: true,
  MN: true,
  MS: true,
  MO: true,
  MT: true,
  NE: true,
  NV: true,
  NH: true,
  NJ: true,
  NM: true,
  NY: true,
  NC: true,
  ND: true,
  OH: true,
  OK: true,
  OR: true,
  PA: true,
  PR: true,
  RI: true,
  SC: true,
  SD: true,
  TN: true,
  TX: true,
  UT: true,
  VT: true,
  VA: true,
  WA: true,
  WV: true,
  WI: true,
  WY: true,
};

const EditLead = ({ dispatch, tmpLead }) => {
  const [lead, setLead] = useState(tmpLead);
  const [moveSizeOptions, setMoveSizeOptions] = useState(moveSizes);

  useEffect(() => {
    if (tmpLead.edited) {
      try {
        document.getElementsByClassName('close icon')[0].click();
      } catch (e) {
        console.log('Failed to close dimmer.');
      }
    }
  },[tmpLead.edited]);

  useEffect(() => {
    setLead({
      ...tmpLead,
      from_state: tmpLead.from_state,
      from_city: tmpLead.from_city,
      to_state: tmpLead.to_state,
      to_city: tmpLead.to_city,
    })
  }, [tmpLead.from_state, tmpLead.from_city, tmpLead.to_state, tmpLead.to_city, tmpLead]);

  const handleModalClose = () => {
    dispatch(clearTempLead());
  };

  const handleChangeFromZip = result => {
    const newFromZip = result.target.value;
    if (newFromZip.length === 5) dispatch(fetchCityStateFromZip(newFromZip, 'from', 'update'));
  };

  const handleChangeToZip = result => {
    const newToZip = result.target.value;
    if (newToZip.length === 5) dispatch(fetchCityStateFromZip(newToZip, 'to', 'update'));
  };

  const editLeadPage = updateLead => {
    updateLead.id = tmpLead.id;
    dispatch(
      editLead({
        ...updateLead,
      }),
    );
  };

  const handleModalOpen = () => {
    dispatch(resetTempLead());
    const { movesize } = tmpLead;
    if (!moveSizeOptions.some(size => size.val_to_submit === movesize)) {
      setMoveSizeOptions(
        moveSizeOptions.concat([
          {
            key: `size_${movesize}`,
            val_to_submit: movesize,
            text: movesize,
            value: movesize,
          },
        ]),
      );
    }
  };

  addValidationRule('isFuture', (values, value, array) => {
    return moment(value).isAfter(moment())
  });

  addValidationRule('isState', (values, value, array) => {
    return value && !!validStates[value.toUpperCase()]
  });

  return (
    <Modal
      onClose={handleModalClose}
      onOpen={handleModalOpen}
      trigger={<Button icon="edit" id="edit_lead" />}
      closeOnDimmerClick={false}
      closeIcon={true}
    >
      <Modal.Header>Edit Lead </Modal.Header>
      <Modal.Content>
        <Form autoComplete='off' noValidate onValidSubmit={editLeadPage}>
          <Form.Group widths={2}>
            <Input
              required
              type="date"
              name="movedate"
              validations="isFuture"
              value={moment(lead.movedate).format('YYYY-MM-DD')}
              label="Move Date"
            />
            <Input
              disabled={tmpLead.created}
              required
              type="text"
              name="name"
              validations="minLength:1"
              value={lead.name}
              label="Name"
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Input
              disabled={tmpLead.created}
              required
              type="text"
              name="email"
              validations="isEmail"
              value={lead.email}
              label="Email"
            />
            <Input
              disabled={tmpLead.created}
              required
              type="text"
              name="phone"
              validations="isNumeric,maxLength:11,minLength:10"
              value={String(lead.phone)}
              label="Phone"
            />
          </Form.Group>
          <h5 className="heading">Move Size</h5>
          <Select
            disabled={tmpLead.created}
            required
            options={moveSizeOptions}
            label="Move Size"
            name="movesize"
            width={6}
            value={lead.movesize}
          />
          <h5 className="heading">Moving From</h5>
          <Form.Group widths={3}>
            <Input
              disabled={tmpLead.created}
              required
              type="text"
              value={String(lead.from_zip)}
              name="from_zip"
              onChange={handleChangeFromZip}
              validations="isNumeric,isLength:5"
              label="Zipcode"
            />
            <Input
              disabled={tmpLead.created}
              required
              type="text"
              name="from_state"
              validations="isState"
              value={lead.from_state}
              label="State"
            />
            <Input
              disabled={tmpLead.created}
              required
              type="text"
              name="from_city"
              validations="minLength:2"
              value={lead.from_city}
              label="City"
            />
          </Form.Group>
          <h5 className="heading">Moving To</h5>
          <Form.Group widths={3}>
            <Input
              disabled={tmpLead.created}
              required
              value={String(lead.to_zip)}
              type="text"
              name="to_zip"
              onChange={handleChangeToZip}
              validations="isNumeric,isLength:5"
              label="Zipcode"
            />
            <Input
              disabled={tmpLead.created}
              required
              type="text"
              name="to_state"
              validations="isState"
              value={lead.to_state}
              label="State"
            />
            <Input
              disabled={tmpLead.created}
              required
              type="text"
              name="to_city"
              validations="minLength:2"
              value={lead.to_city}
              label="City"
            />
          </Form.Group>
          {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
          <h5 className="heading"/>
          <Form.Group widths={4}>
            <Button disabled={tmpLead.status === 'running'} type="submit">
              {tmpLead.created ? 'Lead Updated' : 'Save Changes'}
            </Button>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    tmpLead: state.lead.tmpLead
  };
}
export default connect(mapStateToProps)(EditLead);
