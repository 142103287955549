import _ from 'lodash'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Search } from 'semantic-ui-react'

import { searchLeads } from '../../actions/app'
import { requestLead } from '../../actions/lead'

class LeadSearch extends Component {
  handleResultSelect = (e, { result }) => {
    this.props.dispatch(requestLead(result.id))
    this.searchInputDOM.close()
  }

  handleSearchChange = (e, { value }) => {
    if(value.length < 3) {
      //action to clear search results
      return
    }
    this.props.dispatch(searchLeads(value))
  }

  render(){
    const {searchResults, status} = this.props

    return <Search 
      ref={(searchInputDOM)=>{this.searchInputDOM = searchInputDOM}}
      loading={status !== "ready"}
      onResultSelect={this.handleResultSelect}
      onSearchChange={_.debounce(this.handleSearchChange, 300, {
        leading: true,
      })}
      placeholder="Search Leads..."
      results={searchResults.map(({id, name, phone})=>{
        return {
          id,
          title: `${name} | ${phone}`,
          description: `#${id}`,
          key: `search_result_${id}`
        }
      })}
    />
  }
}

function mapStateToProps(state) {
  return { 
    status: state.app.status,
    searchResults: state.app.searchResults
  }
}

LeadSearch.propTypes = {
  dispatch: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(LeadSearch)