import { keyMirror } from '../modules/helpers';

/**
 * @namespace Constants
 * @desc App constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  EXCEPTION: undefined,
  USER_LOGIN: undefined,
  USER_LOGIN_SUCCESS: undefined,
  USER_LOGIN_FAILURE: undefined,
  USER_LOGOUT: undefined,
  USER_LOGOUT_SUCCESS: undefined,
  USER_LOGOUT_FAILURE: undefined,
  SHOW_ALERT: undefined,
  HIDE_ALERT: undefined,
  REQUEST_LEAD: undefined,
  CREATE_LEAD: undefined,
  CREATE_LEAD_ERROR: undefined,
  DISPOSITION_LEAD: undefined,
  NEW_LEAD: undefined,
  RESET_NEW_LEAD: undefined,
  UPDATE_LEAD: undefined,
  DIAL_LEAD: undefined,
  TRANSFER_LEAD: undefined,
  CALL_STATUS: undefined,
  INBOUND_CALL: undefined,
  INBOUND_CLAIMED: undefined,
  NOBLE_CLAIMED: undefined,
  CLAIM_ON_LOGIN: undefined,
  INBOUND_UNAVAILABLE: undefined,
  LEAD_SEARCH_RESULTS: undefined,
  SEARCH_LEADS: undefined,
  SET_DISPOSITIONS: undefined,
  LOG_MESSAGE: undefined,
  SUBMIT_EXCEPTION: undefined,
  EXCEPTION_RECEIVED: undefined,
  SET_AUTO_PULL: undefined,
  EDIT_LEAD: undefined,
  SET_COVERAGE_STATUS: undefined,
  CLEAR_TEMP_LEAD: undefined,
  RESET_TEMP_LEAD: undefined,
  SESSION_ENDED: undefined,
  REQUEST_COVERAGE: undefined,
  LOG_ACTION: undefined,
  HANGUP: undefined,
  HANGUP_RECEIVED: undefined,
  RECENT_LEAD_RESPONSE: undefined,
  GET_RECENT_LEADS: undefined,
  GET_CACHE: undefined,
  COMPANY_MODE: undefined,
  COMPANY_MODE_RESULT: undefined,
  DISASSOCIATE_LEAD: undefined,
  DISASSOCIATION_FINISH: undefined,
  USER_ROLE: undefined,
  FETCH_CITY_STATE_FROM_ZIP: undefined,
  RESPONSE_CITY_STATE_ZIP: undefined,
  BUYER_AVAILABILITY_STATUS: undefined,
  CHECK_BUYER_AVAILABILITY: undefined,
  CHANGE_AGENT_STATUS: undefined,
  AGENT_STATUS: undefined,
  GET_AGENT: undefined,
});

/**
 * @constant {Object} STATUS
 * @memberof Constants
 */
export const STATUS = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error',
};
