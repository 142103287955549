// @flow
/**
 * @module Actions/Lead
 * @desc Lead Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/index';

export const { dialLead, transferLead, claimCall, nobleClaimed, hangup } = createActions({
  [ActionTypes.DIAL_LEAD]: () => ({}),
  [ActionTypes.TRANSFER_LEAD]: (params: Object) => (params),
  [ActionTypes.CLAIM_CALL]: (callUUID: string) => ({callUUID}),
  [ActionTypes.NOBLE_CLAIMED]: (phoneNumber: string) => ({phoneNumber}),
  [ActionTypes.HANGUP]: () => ({}),
});




