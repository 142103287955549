import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container } from 'styled-minimal';
import { Button, Grid, Popup } from 'semantic-ui-react';
import EditLead from '../containers/EditLead';
import { disassociateLead } from '../actions';
import moment from 'moment';

const formatPhoneNumber = (phoneNumberString) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');

  if (cleaned.length === 11 && cleaned.startsWith('1')) {
    cleaned = cleaned.substring(1);
  }

  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phoneNumberString;
};

const formatDistance = (distance) => {
  return distance ? `${Number(distance).toLocaleString()} Miles` : '';
};

const LeadNameAndLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;  
`;

const LeadName = styled.h2`
  position: relative;
  top: 25px;
  margin-right: 20px;
`;

const LeadLabel = styled.h2`
  margin-right: 20px;
  font-weight: bold;
  color: ${props => props.isFresh ? 'green' : 'red'};
`;

const LeadContainer = styled(Container)`
  align-items: left;
  display: flex;
  flex-direction: column;
`;

const Label = styled.b`
  margin-top: 10px;
`;


const LeadId = styled.span`
  margin-top: 15px;
  font-weight: bold;
`;


const sourceMap = {
  '2M': '2Movers',
  GOT: 'Gotmovers',
  ORGANIC: 'Gotmovers',
  'BVL(G)': 'Budget Van Lines',
  BVL: 'Budget Van Lines',
  MP: 'MoversPro',
  QR: 'QuoteRunner',
  RVL: 'Red Van Lines'
};

const mapSource = source => {
  const name = Object.keys(sourceMap).find(alias => source === alias || source.startsWith(alias));
  return sourceMap[name] || source;
}

const LeadInfo = ({
  buyerAvailability: {
    status,
    message
  },
  currentLead: {
    id,
    company,
    dispositions,
    inbound_call_id,
    name,
    email,
    phone,
    from,
    to,
    distance,
    movedate,
    movesize,
    source,
    openDate,
    appointment_lead_id,
    searched,
    isFresh,
    isLocal,
  }, 
  isAdmin,
  isDialed,
  isSearched,
  dispositionsByID,
  currentLead,
  dispatch
}) => {
  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column width={12}>
          <LeadContainer>
            <LeadId>#{id}</LeadId> 
            {
              appointment_lead_id && inbound_call_id ? <label style={{padding:10, paddingLeft:0, paddingBottom:0}}><strong style={{ backgroundColor :"#FFFF00", padding:10}}>Appointment Available! <a rel='noreferrer' target="_blank" href={`https://www.p4pmarketing.com/manager/leads/lead_call_post.php?lead_id=${appointment_lead_id}`} >Book Appointment</a> </strong> </label>: ''
            }

            <LeadNameAndLabelContainer>
              <LeadName>{name}</LeadName>
              {isFresh && <LeadLabel isFresh>**FRESH LEAD**</LeadLabel>}
              {isLocal && <LeadLabel isLocal>**LOCAL LEAD**</LeadLabel>}
            </LeadNameAndLabelContainer>
            <h3>{email}</h3>
            <Label>Phone:</Label>
            <span>{formatPhoneNumber(phone)}</span>
            <Label>From:</Label>
            <span>{from}</span>
            <Label>To:</Label>
            <span>{to}</span>

            {
              (distance) ? <><Label>Move Distance:</Label><span>{formatDistance(distance)}</span></> : ''
            }
            
            <Label>Move Date:</Label>
            <span style={{color: (moment(movedate).isBefore("2020-08-01") && company && company.name.includes('All My Sons') && [0,5,6].includes(moment(movedate).day())) ? 'red' : 'black' }}>{moment(movedate).format("M/DD/YYYY")}</span>
            { (moment(movedate).isBefore("2020-08-01") && company && company.name.includes('All My Sons') && [0,5,6].includes(moment(movedate).day())) && <span style={{color:'red'}}>All My Sons blackout date</span> }
            <Label>Lead Source:</Label>
            <span>{mapSource(source)}</span>
            <Label>Move Size:</Label>
            <span>{movesize}</span>
            <Label>Matched Buyer:</Label>
            <span>{company.name} - {formatPhoneNumber(company.phone)}</span>
          </LeadContainer>
        </Grid.Column>
        <Grid.Column width={4}>
          <div style={{textAlign: 'right'}}>
            { searched && isAdmin ? <Popup content='Drop lead' trigger={<Button icon="user delete" color="red" onClick={() => { dispatch(disassociateLead(currentLead.id));}} ></Button>} /> : null }
            <EditLead id="update-lead-button">Update Lead</EditLead>
          </div>
        </Grid.Column>
        {
            isLocal && (
                <p style={{padding: '20px', marginLeft: '25px'}}>
                  <LeadLabel isFresh={status}>
                    {message}
                  </LeadLabel>
                </p>
            )
        }
      </Grid.Row>
    </Grid>
  );
};
LeadInfo.propTypes = {
  lead: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentLead: state.lead.currentLead,
    isAdmin : state.user.isAdmin,
    buyerAvailability: state.lead.buyerAvailability,
  };
}


export default connect(mapStateToProps)(LeadInfo); ;
