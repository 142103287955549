// @flow
import { createBrowserHistory } from 'history';
import qs from 'qs';

const h = createBrowserHistory();

h.location = {
  ...h.location,
  query: qs.parse(h.location.search.substr(1)),
  state: {},
};

/* istanbul ignore next */
h.listen(() => {
  h.location = {
    ...h.location,
    query: qs.parse(h.location.search.substr(1)),
    state: h.location.state || {},
  };
});

const { go, goBack, push, replace } = h;

export { go, goBack, push, replace };
export default h;
