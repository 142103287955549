import app from './app';
import user from './user';
import lead from './lead';
import call from './call';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...app,
  ...user,
  ...lead,
  ...call

};
