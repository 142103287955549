/**
 * Configuration
 * @module config
 */

const config = {
  name: 'Rabbit',
  description: 'Welcome To Rabbit',
};

export default config;
