import React from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'semantic-ui-react';
import { transferLead } from '../actions/index';

const loadingStates = {
  call_initiated: true,
  transferring: true,
  dialing: true,
};

const transferDisabledStates = {
  call_initiated: true,
  hangup: true,
  transfer_success: true,
  transfer_fail: true,
  dialing: true,
  incoming_transfer: true,
  transferring: true,
  error_connecting: true,
};

const DispositionHistory = ({ dispositions, dispositionsByID, openDate, dispatch, lead, call }) => {
  const handleTransferClick = phone_company_id => {
    dispatch(transferLead({ toBVL: false, selfService: false, toCompany: phone_company_id }));
  };

  return (
    <Table celled id="disposition-history-table" columns={2}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Disposition</Table.HeaderCell>
          <Table.HeaderCell>Set</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Array.isArray(dispositions) &&
          dispositions
            .slice()
            .filter(disp => !!disp.visible || disp.text)
            .sort((a, b) => new Date(b.time_created) - new Date(a.time_created))
            .map((disp, i) => (
              <Table.Row
                key={`disp_history_${i}`}
                style={{ backgroundColor: disp.dnc || disp.duration < 180 ? '#ff00004d' : 'transparent' }}
                className="disposition-history-row"
              >
                <Table.Cell width={10}>
                  {disp.text || dispositionsByID[disp.call_disposition_id]}
                  {dispositionsByID[disp.call_disposition_id] === 'transferred' ? (
                    <span>
                      {disp.duration < 180 && (
                        <span
                          style={{
                            marginLeft: 50,
                            backgroundColor: 'red',
                            padding: 5,
                            fontWeight: 'bold',
                          }}
                        >
                          Call duration less than 3min. Dropped call?
                        </span>
                      )}
                      <Button
                        floated="right"
                        onClick={() => {
                          handleTransferClick(disp.to_company_id);
                        }}
                        loading={loadingStates[call.state]}
                        disabled={transferDisabledStates[call.state] || lead.id !== call.lead_id}
                      >
                        Transfer Back
                      </Button>
                    </span>
                  ) : (
                    ''
                  )}
                </Table.Cell>
                <Table.Cell width={6}>{new Date(disp.time_created).toLocaleString()}</Table.Cell>
              </Table.Row>
            ))}
        <Table.Row className="disposition-history-row">
          <Table.Cell width={10}>Lead Created</Table.Cell>
          <Table.Cell width={6}>{new Date(openDate).toLocaleString()}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
function mapStateToProps(state) {
  return {
    call: state.call,
    lead: state.lead.currentLead,
  };
}
export default connect(mapStateToProps)(DispositionHistory);
