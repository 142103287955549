/**
 * @module Sagas/Call
 * @desc Call
 */

import { all, apply, call, put, select, takeLatest } from 'redux-saga/effects'
import Push from 'push.js'

import { ActionTypes } from '../constants/index'
import * as selectors from './selectors'

import {socket} from '../modules/socket'

Push.Permission.request()

const audio = new Audio('/media/audio/inbound_ring.mp3')

const claimedCallUUIDS = {}

export function* hangup({type='hangup'}) {
  try {
    yield apply(socket, socket.emit, ['end_call', type])
  } catch (err) {
    console.log(err)
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/call hangup()",
      },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to hangup call', 
        options: { variant: 'failure', icon: 'alert' }
      }
    })
  }
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
} 

export function* handleStatusChange({type, payload}){
  if(payload.state === 'transferring'){
    yield hangup({type: 'terminate'})
  }
}

export function* dialLead({type}) {
  try {
    const leadId = yield select(selectors.leadId)
    yield apply(socket, socket.emit, ['dial_lead', leadId])
  } catch (err) {
    console.log(err)
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/call dialLead()",
      },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to dial lead', 
        options: { variant: 'failure', icon: 'alert' }
      }
    })
  }
}

export function* transferLead({type, payload}) {
  try {
    const leadId = yield select(selectors.leadId)
    yield apply(socket, socket.emit, ['transfer_lead', {leadId, ...payload}])
  } catch (err) {
    console.log(err)
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/call transferLead()",
      },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to transfer lead', 
        options: { variant: 'failure', icon: 'alert' }
      }
    })
  }
}

// eslint-disable-next-line require-yield
export function* inboundClaimed({payload}){
  document.getElementById('create_lead').click()
}

// eslint-disable-next-line require-yield
export function* inboundUnavailable({payload}){
  Push.create('Too Slow Try another!', {
    body: 'Too slow!',
    requireInteraction: true,
    icon: '/media/icons/slow.bmp',
    timeout: 3000,
    vibrate: [100, 100, 100]
  })
}

export function* inboundCall({payload:{source, callUUID}}){
  const callStatus = yield select(selectors.callStatus)
  const userGroupId = select(selectors.userGroupId)
  
  if(userGroupId !== 9 && ['','hangup','transfer_success','transfer_fail','error_connecting'].includes(callStatus)){
    audio.play()
  }

  Push.create(`Inbound Customer Call ${source?'- '+source:''}`, {
    body: `Click to accept call!`,
    requireInteraction: true,
    icon: `/media/icons/phone-green.png`,
    timeout: 15000,
    vibrate: [100, 100, 100],
    onClick: function () {
      audio.pause()
      audio.currentTime = 0
      if(!claimedCallUUIDS[callUUID]){
        claimedCallUUIDS[callUUID] = true
        socket.emit('claim_call', callUUID)
      }
    }
  }) 
}

export function* nobleClaimed({type, payload }){
  try {
    const userAuthenticated = yield select(selectors.userAuthenticated)
    if(userAuthenticated){
      yield apply(socket, socket.emit, ['noble_claimed', payload])
    }else{
      yield put({
        type: ActionTypes.CLAIM_ON_LOGIN,
        payload
      })
    }
  }catch(err){
    console.log(err)
    yield put({
      type: ActionTypes.LOG_ERROR,
      payload: {
        err,
        type,
        func: "sagas/call nobleClaimed()",
      },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'Failed to process claimed lead', 
        options: { variant: 'failure', icon: 'alert' }
      }
    })
  }
}

export function* nobleClaimOnLogin({payload}) {
  const claimOnLogin = yield select(selectors.claimOnLogin)
  if(claimOnLogin){
    yield put({
      type: ActionTypes.NOBLE_CLAIMED,
      payload: claimOnLogin
    })
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.DIAL_LEAD, dialLead),
    takeLatest(ActionTypes.TRANSFER_LEAD, transferLead),
    takeLatest(ActionTypes.CALL_STATUS, handleStatusChange),
    takeLatest(ActionTypes.INBOUND_CLAIMED, inboundClaimed),
    takeLatest(ActionTypes.INBOUND_CALL, inboundCall),
    takeLatest(ActionTypes.INBOUND_UNAVAILABLE, inboundUnavailable),
    takeLatest(ActionTypes.NOBLE_CLAIMED, nobleClaimed),
    takeLatest(ActionTypes.HANGUP, hangup),
    takeLatest(ActionTypes.USER_LOGIN_SUCCESS, nobleClaimOnLogin),
  ])
}
