import { handleActions } from '../modules/helpers';

import { STATUS, ActionTypes } from '../constants/index';


export const leadState = {
  status: STATUS.IDLE,
  exceptionReported: false,
  newLead: {
    status: STATUS.IDLE,
    error: false,
    created: false,
    from_state:'',
    from_city:'',
    to_state:'',
    to_city:''
  },
  tmpLead: {
    status: STATUS.IDLE,
    edited: false,
    from_state:'',
    from_city:'',
    to_state:'',
    to_city:''
  },
  currentLead: {
    id: null,
    status: STATUS.IDLE,
  },
  buyerAvailability: {
    status: false,
    message: ''
  }
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  lead: handleActions(
    {
      [ActionTypes.BUYER_AVAILABILITY_STATUS]: (draft, {payload}) => {
        draft.buyerAvailability.message = payload.message;
        draft.buyerAvailability.status = payload.status;

        Object.assign(draft.buyerAvailability, payload)
      },
      [ActionTypes.REQUEST_LEAD]: draft => {
        draft.currentLead = {
          id: null,
          status: STATUS.RUNNING,
        };
        draft.buyerAvailability = { status: false, message: '' }
      },
      [ActionTypes.CALL_STATUS]: (draft, {payload}) => {
        if(payload.lead_id === draft.currentLead.id){
          if(payload.state === 'call_live') {
            draft.currentLead.dialed = true;
          }
          
          if(payload.state === 'transfer_success') {
            draft.currentLead.state = 'transferred';
          }
        }
      },      
      [ActionTypes.NEW_LEAD]: (draft, { payload: lead }) => {
        draft.status = STATUS.READY;
        draft.currentLead.status = STATUS.READY;
        draft.currentLead.state = 'pulled';
        Object.assign(draft.currentLead, lead);
        Object.assign(draft.tmpLead, lead);
      },
      [ActionTypes.CREATE_LEAD]: draft => {
        draft.newLead.status = STATUS.RUNNING;
      },
      [ActionTypes.CREATE_LEAD_ERROR]: draft => {
        draft.newLead.error = true
      },
      [ActionTypes.EDIT_LEAD]: (draft, { payload }) => {
        draft.tmpLead = {
          status: STATUS.RUNNING,
          ...payload
        };
      },
      [ActionTypes.EXCEPTION_RECEIVED]: draft => {
        draft.currentLead.disposition = 'exception'
      },
      
      [ActionTypes.RESET_NEW_LEAD]: draft => {
        draft.newLead = leadState.newLead;
      },
      [ActionTypes.CLEAR_TEMP_LEAD]: draft => {
        draft.tmpLead = leadState.newLead;
      },
      [ActionTypes.RESET_TEMP_LEAD]: draft => {
        draft.tmpLead.edited  = false;
        Object.assign(draft.tmpLead, draft.currentLead);
      },
      [ActionTypes.UPDATE_LEAD]: (draft, { payload: lead }) => {
        if (lead.state === 'lead_created') {
          draft.newLead.created = true;
          draft.newLead.status = STATUS.READY;
        }

        if (lead.state === 'lead_edited') {
          draft.tmpLead.status = STATUS.READY;
          draft.tmpLead.edited = true;
          Object.assign(draft.currentLead, draft.tmpLead);
        }

        if (lead.id === draft.currentLead.id) {
          draft.status = STATUS.READY;
          draft.currentLead.status = STATUS.READY;
          
          if (lead.state === 'dispositioned') {
            draft.currentLead.dispositions.push({
              call_disposition_id: lead.disposition_id,
              time_created: new Date(),
              visible: true,
            });
          }

          if (lead.state === 'transferred') {
            let transferredText = `Transferred to: ${draft.currentLead.company.name} - ${draft.currentLead.company.phone}`
            if(draft.currentLead.transferDetails.toBVL) {
              transferredText = "Transferred to BVL"
            }else if(draft.currentLead.transferDetails.selfService) {
              transferredText = "Transferred to self-service"
            }
            draft.currentLead.dispositions.push({
              call_disposition_id: 8,
              time_created: new Date(),
              text: transferredText,
            });
          }

          Object.assign(draft.currentLead, lead);
        } else {
          Object.assign(draft, {
            status: STATUS.ERROR,
            message: 'Attempting to update wrong lead',
            currentLead: {
              id: null,
              status: STATUS.ERROR,
            }
          });
        }
      },
      [ActionTypes.INBOUND_CLAIMED]: (draft, { payload }) => {
        Object.assign(draft, {
          status: STATUS.READY,
          state: 'creating_new_lead',
          newLead: {
            ...payload,
            status: STATUS.READY,
          },
        });
      },
      [ActionTypes.SET_COVERAGE_STATUS]: (draft, { payload: { coverageAvailable } }) => {
        if(!draft.currentLead.id && !coverageAvailable){
          draft.status = STATUS.READY
          draft.currentLead.status = STATUS.READY
        }
      },
      [ActionTypes.TRANSFER_LEAD]: (draft, { payload}) => {
        draft.currentLead.transferDetails = payload
      },
      [ActionTypes.DISASSOCIATION_FINISH]: (draft, { payload}) => {
        if(draft.currentLead.id === payload.lead_id) {
          draft.currentLead = {
            id: null,
            status: STATUS.IDLE,
          }
        }
      },
      [ActionTypes.RESPONSE_CITY_STATE_ZIP]: (draft, { payload}) => {
        let leadMode = payload.mode === 'create' ? draft.newLead : draft.tmpLead

        if(payload.zipinfo.length > 0) {
          if(payload.codeType === 'from') {
            leadMode.from_state = payload.zipinfo[0].state
            leadMode.from_city = payload.zipinfo[0].city
          }else if(payload.codeType ==='to') {
            leadMode.to_state = payload.zipinfo[0].state
            leadMode.to_city = payload.zipinfo[0].city
          }
        }else{
          if(payload.codeType === 'from') {
            leadMode.from_state = ''
            leadMode.from_city = ''
          }else if(payload.codeType ==='to') {
            leadMode.to_state = ''
            leadMode.to_city = ''
          }
        }
      },
    },
    leadState,
  ),
};
